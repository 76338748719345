import React, { useState, useEffect, useContext } from 'react';
// import PricingTable from '../components/PricingTable'
// import {ReactComponent as KomIcon} from '../../src/assets/gold_KOM.svg'
import {ReactComponent as TextractifyIcon} from '../../src/assets/images/textractifyIcon.svg'
import storage from "../utils/local_storage"
import { useNavigate } from 'react-router-dom';
import filler_image from '../assets/images/vidToSheet.jpeg'
import video_input from '../assets/images/video_input.png'
import cyber_charging from '../assets/images/cyber_charging.gif'
import data_output from '../assets/images/data_output.png'
import {ReactComponent as CurveArrowIcon} from '../assets/curve-arrow.svg'
import apiClient from '../utils/apiClient'; // Assume this is configured to work with your backend API
import { StateContext } from '../stateContext';
import { TableCellsIcon, PresentationChartLineIcon, ChatBubbleBottomCenterIcon, CircleStackIcon, LanguageIcon, DocumentChartBarIcon } from '@heroicons/react/24/outline'

import { useUser } from '../utils/UserProvider'; // Adjust the import path as necessary

function HomePage() {
  const {setToastData} = useContext(StateContext);
  // const token = localStorage.getItem('Token');
  const [demoData, setDemoData] = useState(null); // Track clicked boxes
  const [email, setEmail] = useState('');

  const navigate = useNavigate();
  const { profile, user, signOut, signIn } = useUser();

  // const handleDashboardButtonClick = () => {
  //   navigate('/dashboard'); // Replace '/path-to-navigate' with your route
  // };

  const DashboardButton = () => {
    return (
      <button className="btn btn-success text-lg" onClick={()=>navigate('/uploads')}>Go to Dashboard</button>
    )
  }

    const LogInButton = () => {
    return (
      <div>
        <button className="btn btn-success text-lg" onClick={()=>navigate('/login')}>Sign up for free</button>
      </div>
    )
  }

  useEffect(() => {
    downloadDemoData()
  }, []);

  const downloadDemoData = async () => {
    try {
      const response = await apiClient.get(`/getUploadDetails/demo`);
      setDemoData(response.data)
    } catch (error) {
      console.error('Error fetching upload details:', error);
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}

  const handleEmailSubmit = async () => {
    // event.preventDefault();
    if (!validateEmail(email)) {
      setToastData({text:"Please submit a valid email", show:true})
      return
    }

    try {
      const response = await apiClient.post(`/emails`, { email });
      setToastData({text:"Thank you for connecting with Textractify", show:true})
      setEmail(''); // Clear the input after successful submission
    } catch (error) {
      console.error('Error submitting email:', error);
      alert('Error submitting email. Please try again later.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center space-y-8 m-4">
      {/* Row 1 */}
      <div className="flex flex-col lg:flex-row justify-center min-h-1/2 items-center mt-4 w-full">
        <div className="flex flex-col text-center m-4 w-full lg:w-1/2">
          <div className="flex flex-col items-center justify-center mx-auto pb-4">
            <TextractifyIcon width="75" height="75" className=" mr-4"/>
            <p className="text-4xl font-bold">Textractify</p>
          </div>
            <p className="text-2xl font-bold">Unlock the Value in Your Media</p>
            <p className="text-md pb-4">Textractify transforms your videos and photos into actionable, editable, and shareable text.</p>
            <p className="text-md font-semibold pb-4">From videos or photos to structured data, in seconds.</p>
          <button className="btn btn-normal text-lg w-sm mx-auto my-4" onClick={() =>  navigate('/demo', { state: { demoData } })}>Try the demo</button>
        {user ? 
          <div className="flex flex-col justify-center items-center">
            <DashboardButton/>
          </div> : 
          <div className="flex flex-col justify-center items-center">
            <LogInButton/>
          </div>}
      {/* {token ? <DashboardButton/>:<SignupButton/>} */}
        </div>
        <div className="relative py-8 px-8 my-4 flex justify-center items-center w-full">
          <div className="flex items-center justify-center w-1/2 lg:w-3/5 px-0 lg:px-8 xl:px-10">
            <img 
              src={cyber_charging}
              alt="video input"
              className="flex-grow  transform translate-y-12 -rotate-6 rounded-lg z-0" 
            />
            <img 
              src={data_output} 
              alt="data output"
              className="flex-grow  transform rotate-6 rounded-lg z-1" 
            />
            <div className="absolute z-2 transform scale-x-[-1]" style={{ width: '30vw', maxWidth: '120px'}}>
              <CurveArrowIcon className="w-full h-auto" />
            </div>
          </div>
        </div>

      </div>
      <div className="divider"></div>
      <div className="container mx-auto px-4">
        <p className="text-2xl font-bold text-center pb-4">Features</p>
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-6 justify-items-center bg-base-200 rounded-lg">
    {/* Feature 1 */}
    <div className="border bg-base-100 text-center p-8 max-w-xs rounded-lg">
        <CircleStackIcon className="w-12 h-12 inline-block ml-1" />
      <h3 className="text-lg font-semibold">Numerical Data from Videos</h3>
      <p className="text-sm">Automatically extract and analyze numerical data displayed in videos to save time and improve accuracy.</p>
    </div>
    {/* Feature 2 */}
    <div className="border bg-base-100 text-center p-8 max-w-xs rounded-lg">
      <PresentationChartLineIcon className="w-12 h-12 inline-block ml-1" />
      <h3 className="text-lg font-semibold">Slide Text from Presentation Recording</h3>
      <p className="text-sm">Capture text from slideshows in videos to efficiently gather information without manual transcription.</p>
    </div>
    {/* Feature 3 */}
    <div className="border bg-base-100 text-center p-8 max-w-xs rounded-lg">
      <ChatBubbleBottomCenterIcon className="w-12 h-12 inline-block ml-1" />
      <h3 className="text-lg font-semibold">Video Caption Collection</h3>
      <p className="text-sm">Retrieve captions directly from video frames, ideal for content where captions are not available as separate text files.</p>
    </div>
    {/* Feature 4 */}
    <div className=" border bg-base-100 text-center p-8 max-w-xs rounded-lg">
      <TableCellsIcon className="w-12 h-12 inline-block ml-1" />
      <h3 className="text-lg font-semibold">Spreadsheet Export</h3>
      <p className="text-sm">Export extracted data into CSV format, making it easy to integrate with other data analysis tools.</p>
    </div>
    {/* Feature 5 */}
    <div className="hidden md:block border bg-base-100 text-center p-8 max-w-xs rounded-lg">
      <LanguageIcon className="w-12 h-12 inline-block ml-1" />
      <h3 className="text-lg font-semibold">Translate Captions</h3>
      <p className="text-sm">Easily translate extracted captions into multiple languages, enhancing accessibility and understanding.</p>
    </div>
    {/* Feature 6 */}
    <div className="hidden md:block border bg-base-100 text-center p-8 max-w-xs rounded-lg">
    <DocumentChartBarIcon className="w-12 h-12 inline-block ml-1" />
    <h3 className="text-lg font-semibold">Data Series Cleaning</h3>
    <p className="text-sm">Easily clean up extracted data to hone in on the exact data of interest before exporting.</p>
    </div>

  </div>
    <div className="items-center flex flex-col w-full pt-4 gap-2">
          <p className="text-lg text-center">See our blog for examples and use cases.</p>
          <button className="btn btn-normal text-lg" onClick={()=>navigate('/blog')}>Blog</button>
    </div>
</div>
      {/* <div className="divider"></div> */}

      {/* <div className="flex flex-col lg:flex-row justify-center min-h-1/2 items-center lg:px-8">
        <div className="flex-1 text-center p-4">
            <p className="text-2xl">Multiple Applications</p>
            <div className="flex justify-center items-center text-center tooltip" data-tip="ZestyRides Pro Feature"></div>
            <div className="flex flex-col items-center justify-center">
                <p className="text-md font-bold">Extract data trapped in videos of various content types.</p>
                <ul className="text-left list-disc list-inside mx-auto text-md pt-4">
                    <li>Save numerical data to spreadsheets.</li>
                    <li>Extract and translate captions.</li>
                    <li>Convert slideshow videos to text.</li>
                </ul>
            </div>
        </div>  
      </div>
      <div className="divider"></div>
      <div className="flex flex-col lg:flex-row justify-center min-h-1/2 items-center lg:px-8">
        <div className="flex-1 text-center p-4">
            <p className="text-2xl">Experience Seamless Media Conversion</p>
            <div className="flex justify-center items-center text-center tooltip" data-tip="ZestyRides Pro Feature"></div>
            <div className="flex flex-col items-center justify-center">
                <p className="text-md font-bold">Effortlessly extract and analyze data, automate workflows, and gain insights like never before.</p>
                <ul className="text-left list-disc list-inside mx-auto text-md pt-4">
                    <li>Transform numerical displays and on-screen overlays into editable spreadsheets.</li>
                    <li>Convert presentation recordings into searchable text documents.</li>
                    <li>Accelerate data analysis and content repurposing with advanced automation.</li>
                </ul>
            </div>
        </div>  
      </div> */}
      {/* <div className="divider"></div> */}
      {/* <div className="flex flex-col gap-4">
        <p className="text-lg text-center font-bold">Keep up with Textractify</p>
        <p className="text-md text-center">Enter your email to stay up to date with our launch.</p>
        <div className="flex flex-row gap-4">
          <input
            type="email"
            className="input input-bordered px-4"
            placeholder="Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button className="btn btn-success" onClick={handleEmailSubmit}>Submit</button>
        </div>
      </div> */}
      <div className="divider"></div>
<div className="container mx-auto px-4 py-8">
  <h2 className="text-2xl font-bold text-center mb-4">Simple and Transparent Pricing</h2>
    <p className="text-lg text-center pb-4">Pay as you go with credits to process your media. Each frame analyzed consumes one credit, giving you full control over your usage.</p>
  <div className="flex flex-col md:flex-row bg-base-200 p-6 rounded-lg shadow-md gap-8">
    <div className=" p-4 bg-base-100 rounded-lg">
      <h3 className="text-xl font-semibold pb-4">Get Free Credits at Signup</h3>
      <p>New users receive <strong>50 free credits</strong> to test the capabilities of Textractify. Try processing your videos and presentations without any initial cost.</p>
    </div>
    <div className=" p-4 bg-base-100 rounded-lg">
      <h3 className="text-xl font-semibold pb-4">Need More Credits?</h3>
      <p>Purchase additional credits at any time directly through the platform. Choose from flexible packages that fit your needs and scale with your usage. Starting at $1 for 100 credits.</p>
      {/* <button className="btn btn-primary mt-4">Buy Credits</button> */}
    </div>
  </div>
</div>

     
    {user ? 
      <div className="flex flex-col justify-center items-center">
        <DashboardButton/>
      </div> : 
      <div className="flex flex-col justify-center items-center">
        <p className="text-center text-2xl font-bold pb-4">Start Textracting</p>
        <LogInButton/>
      </div>}
    </div>
    )
  ;
}

export default HomePage;