import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { StateContext } from '../stateContext';
import apiClient from "../utils/apiClient";
import { useUser } from '../utils/UserProvider'; // Adjust the import path as necessary
import {getFileTimestamp} from '../utils/photoUtils'
import {formatSeconds} from '../utils/timeUtils'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { languages } from '../utils/cloudVisionLanguages'; // Adjust the path as necessary
import PricingPage from '../components/PricingPage';
import PresentationResultsModal from '../components/PresentationResultsModal';

function UploadPage() {
    const { user, profile, fetchUserProfile } = useUser();
    const {setToastData} = useContext(StateContext);
    const [currentStep, setCurrentStep] = useState(1); // Assuming step starts at 1
    const steps = ['Upload', 'Process', 'View'];
    const [sortPreference, setSortPreference] = useState("name");
    const [isLoading, setIsLoading] = useState(false);
    const [slideEstimationProgress, setSlideEstimationProgress] = useState(0);
    const [estimatingSlideCount, setEstimatingSlideCount] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [showPricing, setShowPricing] = useState(false);
    const [uploadName, setUploadName] = useState("");
    const [uploadFiles, setUploadFiles] = useState(null);
    const [timestamps, setTimestamps] = useState(null);
    const [jobStatus, setJobStatus] = useState('Select files to upload');
    const [jobProgress, setJobProgress] = useState(0); // Track upload progress
    const [isVideo, setIsVideo] = useState(false); // Track upload progress
    const [mediaContent, setMediaContent] = useState("numerical"); // Track upload progress
    const [estimatedDuration, setEstimatedDuration] = useState(null)
    const [autoSlides, setAutoSlides] = useState(true)
    const [inputKey, setInputKey] = useState(Date.now());
    const [uploadID, setUploadID] = useState(null)
    const [frameRateValue, setFrameRateValue] = useState(7); // For slider
    const frameRateIncrements = [1, 3, 5, 10, 15, 30, 45, 60, 120, 180, 240, 300, 600, 900, 1800, 2700, 3600]; // Time in seconds
    const [frameRate, setFrameRate] = useState(frameRateIncrements[frameRateValue]); // For actual frame rate
    const [exifTimestamps, setExifTimestamps] = useState(false); // For actual frame rate
    const [originalLanguage, setOriginalLanguage] = useState(null);
    const [targetLanguage, setTargetLanguage] = useState(null);
    const [downloadURL, setDownloadURL] = useState(null);


    const navigate = useNavigate();

  async function handleFileChange(e) {
    const validImageExtensions = ["png", "jpeg", "jpg", "PNG", "JPEG", "JPG"];
    const validVideoExtensions = ["mp4", "mov", "MP4", "MOV"];
    setTimestamps(null)
    setEstimatedDuration(null)
    setIsVideo(false)
    setSlideEstimationProgress(0)
    const files = Array.from(e.target.files); // Convert FileList to an array
    console.log(e.target.files)
    const videoCount = files.filter(file => file.type.startsWith('video/') && validVideoExtensions.includes(file.name.split('.').pop())).length;
    const allSameFileType = files.length > 0 && files.every(file => file.type === files[0].type);
    const allPhotos = files.every(file => file.type.startsWith('image/') && validImageExtensions.includes(file.name.split('.').pop()));

    if (files.length === 0 ) {
      console.log("no files")
      setEstimatedDuration(null)
      setIsVideo(false)
      console.log("no files")
      setInputKey(Date.now()); // Changing key will re-render the input and clear it
    }
    else if (videoCount === 1) {
      // If it's a single video, estimate its length
      setIsVideo(true)
      const duration = await estimateVideoLength(files[0]);
      // if (mediaContent === "presentation"){
      //   // setEstimatingSlideCount(true)
      //   const theseTimestamps = await scanVideoForSlideChanges(files[0])
      //   console.log(theseTimestamps)
      //   // setEstimatingSlideCount(false)
      //   setTimestamps(theseTimestamps)
      // }
      setEstimatedDuration(duration)
      console.log({duration})
    } else if (videoCount > 1) {
      setToastData({text:"Please upload one .mov or .mp4 at a time", show:true})
      setUploadFiles(null)
      setInputKey(Date.now()); // Changing key will re-render the input and clear it
    } else if (!allSameFileType){
      setToastData({text:"Please ensure multiple uploads are the same file type.", show:true})
      setUploadFiles(null)
      setInputKey(Date.now()); // Changing key will re-render the input and clear it
    } else if (!allPhotos){
      setToastData({text:"Ensure multiple uploads are all photos and either .png or .jpeg.", show:true})
      setUploadFiles(null)
      setInputKey(Date.now()); // Changing key will re-render the input and clear it
    }
    else {
      // If it's photos, provide sorting options
      setIsVideo(false)
      setEstimatedDuration(null)
      if (sortPreference === "name"){
        const sortedByName = files.sort((a, b) => a.name.localeCompare(b.name));
        setUploadFiles(sortedByName)
      } else if (sortPreference === "timestamp"){
      setUploadFiles(sortFilesByTimestamp(files))
      }
    }
    setUploadFiles(files);
    setJobStatus('Ready to upload');
    setJobProgress(0); // Reset progress on new file selection
  }

  function startOver(){
    setCurrentStep(1)
    setUploadFiles(null)
    setIsVideo(false)
    setEstimatedDuration(null)
    setJobProgress(0)
    setJobStatus("Ready to Upload")
    setUploadName(null)
    setMediaContent("numerical")
    setExifTimestamps(false); // For actual frame rate
    setOriginalLanguage(null);
    setTargetLanguage(null);
    setDownloadURL(null);
    setTimestamps(null)
    setSlideEstimationProgress(0)

  }

  async function handleUploadOpen(){
    if (isLoading) return
    setIsLoading(true)
    if (mediaContent !== "presentation"){
    navigate(`/view-upload/${uploadID}`); 
    } else {
      try {

        const response = await apiClient.get(`/getUploadDetails/${uploadID}`);
        console.log({response})
        const resultsURL = response?.data?.results?.resultsUrl
        console.log({resultsURL})
        if(resultsURL) {
          setDownloadURL(resultsURL)
          setIsOpen(true)
        }
      } catch {  
        setToastData({text:"Results not yet available.", show:true})
      } finally {
        setIsLoading(false)
      }
    }

  }



useEffect(() => {
  let statusIntervalId;
  let progressIntervalId;

  if (currentStep === 2) {
    setJobProgress(0);
    setJobStatus("Processing");

    // Start checking upload status every 5 seconds
    statusIntervalId = setInterval(() => {
      checkUploadStatus();
    }, 2500);

    // Start incrementing job progress every x seconds (let's say every 1 second for this example)
    progressIntervalId = setInterval(() => {
      setJobProgress((prevProgress) => (prevProgress + 5 > 100 ? 100 : prevProgress + 5));
    }, 1000); // Adjust the time as needed
  }

  if (currentStep === 3){
    if (statusIntervalId) {
      clearInterval(statusIntervalId);
    }
    if (progressIntervalId) {
      clearInterval(progressIntervalId);
    }
  }

  return () => {
    // Clear intervals when the component unmounts or currentStep changes
    if (statusIntervalId) {
      clearInterval(statusIntervalId);
    }
    if (progressIntervalId) {
      clearInterval(progressIntervalId);
    }
  };
}, [currentStep]);

  // const handleSortChange = async (e) => {
  //   const newSortPreference = e.target.value;
  //   setSortPreference(newSortPreference);
  //   if (!uploadFiles) return

  //   let sortedFiles;
  //   if (newSortPreference === 'name') {
  //     // Sort by name immediately
  //     sortedFiles = [...uploadFiles].sort((a, b) => a.name.localeCompare(b.name));
  //   } else if (newSortPreference === 'timestamp') {
  //     // Sort by timestamp immediately (this function should be implemented to sort based on timestamp)
  //     sortedFiles = await sortFilesByTimestamp([...uploadFiles]);
  //   }
  //   console.log({newSortPreference, sortedFiles})
  //   setUploadFiles(sortedFiles);
  // };

// async function extractAndUploadFrames(videoFile, urls) {
//   const video = document.createElement('video');
//   const canvas = document.createElement('canvas');
//   const context = canvas.getContext('2d');

//   video.src = URL.createObjectURL(videoFile);
//   await new Promise((resolve) => (video.onloadedmetadata = resolve)); // Wait for video metadata to load
  
//   canvas.width = video.videoWidth;
//   canvas.height = video.videoHeight;

//   let currentFrame = 0;
//   const duration = video.duration;
//   const captureInterval = frameRate
//   let currentTime = 0;
//   const totalFrames = Math.floor(duration / captureInterval); // Calculate total frames to be captured and uploaded
//   console.log({duration, captureInterval, totalFrames})
//   while (currentTime <= duration) {
//     // eslint-disable-next-line no-loop-func
//     await new Promise((resolve) => {
//       video.currentTime = currentTime;
//       video.onseeked = () => {
//         context.drawImage(video, 0, 0, canvas.width, canvas.height);
//         canvas.toBlob(async (blob) => {
//           const frameUrl = urls[currentFrame];
//           await uploadFile(blob, frameUrl); // Use your existing upload logic here
          
//           currentFrame++;
//           const progressPercentage = (currentFrame / totalFrames) * 100;
//           setJobProgress(progressPercentage); // Update job progress state
//           resolve();
//         }, 'image/jpeg'); // Specify the desired image format
//       };
//     });

//     currentTime += captureInterval;
//     console.log({currentTime})
//   }

//   console.log('Finished capturing and uploading frames');
// }

async function extractAndUploadFrames(videoFile, urls, timestamps = null) {
  const video = document.createElement('video');
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  video.src = URL.createObjectURL(videoFile);
  await new Promise(resolve => video.onloadedmetadata = resolve);

  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  let currentFrame = 0;
  const duration = video.duration;

  if (timestamps) {
    // Use provided timestamps
    for (const time of timestamps) {
      await captureAndUploadFrame(time);
    }
  } else {
    // Use the existing interval-based method
    const captureInterval = frameRate;
    let currentTime = 0;
    while (currentTime <= duration) {
      await captureAndUploadFrame(currentTime);
      currentTime += captureInterval;
    }
  }

  console.log('Finished capturing and uploading frames');

  async function captureAndUploadFrame(time) {
    await new Promise(resolve => {
      video.currentTime = time;
      video.onseeked = async () => {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(async blob => {
          const frameUrl = urls[currentFrame++];
          await uploadFile(blob, frameUrl);
          const progressPercentage = (currentFrame / urls.length) * 100;
          setJobProgress(progressPercentage);
          resolve();
        }, 'image/jpeg');
      };
    });
  }
}

async function isSignificantChange(previousImageData, currentImageData, threshold = 0.01) {
    let diffCount = 0;
    const totalPixels = currentImageData.data.length / 4; // Each pixel has 4 data points (R, G, B, A)

    // Compare the current and previous image data
    for (let i = 0; i < currentImageData.data.length; i += 4) {
        // Convert to grayscale to simplify comparison
        const currentGray = (currentImageData.data[i] + currentImageData.data[i + 1] + currentImageData.data[i + 2]) / 3;
        const previousGray = (previousImageData.data[i] + previousImageData.data[i + 1] + previousImageData.data[i + 2]) / 3;

        // Check if the difference is greater than a threshold
        if (Math.abs(currentGray - previousGray) > threshold * 255) {
            diffCount++;
        }
    }

    const changeRatio = diffCount / totalPixels;
    // Consider it a significant change if the ratio of different pixels exceeds the threshold
    return changeRatio > threshold;
}

async function estimateVideoLength(file) {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(file);
    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      URL.revokeObjectURL(url);
      resolve(video.duration);
    };

    video.onerror = () => {
      URL.revokeObjectURL(url);
      reject(new Error('Error loading video file'));
    };

    video.src = url;
  });
}

// async function sortFilesByTimestamp(files) {
//     const fileTimestamps = await Promise.all(files.map(file => getFileTimestamp(file)));
    
//     // Combine files and timestamps
//     const filesWithTimestamps = files.map((file, index) => ({
//       file,
//       timestamp: exifTimestamps ? fileTimestamps[index] || file.lastModified : file.lastModified, // Fallback to lastModified if no EXIF timestamp
//     }));

//     // Sort by timestamp
//     filesWithTimestamps.sort((a, b) => a.timestamp - b.timestamp);

//     // After sorting, calculate the estimated duration
//     const duration = filesWithTimestamps[filesWithTimestamps.length - 1].timestamp - filesWithTimestamps[0].timestamp;
//     const estimatedDurationInSeconds = duration / 1000; // Convert milliseconds to seconds

//     // Set the estimated duration (make sure to have this state and setter defined in your component)
//     setEstimatedDuration(estimatedDurationInSeconds);

//     console.log(`Estimated duration: ${estimatedDurationInSeconds} seconds`);

//     // Return sorted files
//     return filesWithTimestamps.map(({ file }) => file);
// }

async function calculateEstimatedDuration(files) {
  console.log({files})
  const fileTimestamps = exifTimestamps
    ? await Promise.all(files.map(file => getFileTimestamp(file)))
    : files.map(file => file.lastModified);

  const sortedTimestamps = fileTimestamps.slice().sort((a, b) => a - b);
  const duration = sortedTimestamps[sortedTimestamps.length - 1] - sortedTimestamps[0];
  const estimatedDurationInSeconds = duration / 1000; // Convert milliseconds to seconds
  console.log({estimatedDurationInSeconds})
  setEstimatedDuration(estimatedDurationInSeconds);
}

useEffect(() => {
  fetchUserProfile();
}, []); // Ensure all dependencies are included

useEffect(() => {
  async function processUploadFiles() {
    if (uploadFiles && uploadFiles.length > 1 && !isVideo) {
      console.log("hi", { uploadFiles });
      calculateEstimatedDuration(uploadFiles);
    }
  }

  processUploadFiles();
}, [exifTimestamps, uploadFiles, sortPreference]); // Ensure all dependencies are included

useEffect(() => {
  async function processUploadFiles() {
  if (uploadFiles && uploadFiles.length === 1 && isVideo && mediaContent === "presentation" && slideEstimationProgress !== 100) {
      // setEstimatingSlideCount(true)
      const theseTimestamps = await scanVideoForSlideChanges(uploadFiles[0]);
      // setEstimatingSlideCount(false)
      console.log(theseTimestamps);
      setTimestamps(theseTimestamps);
    }
  }

  processUploadFiles();
}, [uploadFiles, isVideo, mediaContent]); // Ensure all dependencies are included


async function sortFilesByTimestamp(files) {
  const fileTimestamps = await Promise.all(files.map(file => getFileTimestamp(file)));

  const filesWithTimestamps = files.map((file, index) => ({
    file,
    timestamp: exifTimestamps ? fileTimestamps[index] || file.lastModified : file.lastModified,
  }));

  filesWithTimestamps.sort((a, b) => a.timestamp - b.timestamp);

  return filesWithTimestamps.map(({ file }) => file);
}

const handleSortChange = async (e) => {
  const newSortPreference = e.target.value;
  setSortPreference(newSortPreference);
  if (!uploadFiles) return;

  let sortedFiles;
  if (newSortPreference === 'name') {
    sortedFiles = [...uploadFiles].sort((a, b) => a.name.localeCompare(b.name));
  } else if (newSortPreference === 'timestamp') {
    sortedFiles = await sortFilesByTimestamp([...uploadFiles]);
  }
  setUploadFiles(sortedFiles);
};


async function checkUploadStatus(){
  try{

    const uploadStatusResponse = await apiClient.get(`/checkUploadStatus/${uploadID}`)
    console.log(uploadStatusResponse.data)
    if (uploadStatusResponse.data.status === "processed"){
      setCurrentStep(3)
      setJobStatus("processed")
    }
  } catch(error){
    console.log(error.message)
  }
}

const handleUpload = async () => {
  if (!user || !uploadFiles || isLoading) return;
  if (profile?.credits <= 0) {
    setToastData({text:"Not enough credits, purchase some more before uploading.", show:true})
    return
  }
  if (!uploadName){
    setToastData({text:"Please name your upload before uploading.", show:true})
    return
  }
  if (mediaContent === "captions" && originalLanguage === targetLanguage){
    setToastData({text:"Ensure original and target languages are not equal.", show:true})
    setTargetLanguage(null)
    return
  }
  setIsLoading(true)
  const singleFile = uploadFiles[0];
  const isVideo = singleFile.type.startsWith("video/");
  // let timestamps = [];
  let timestampStrings = []
  let targetFrameRate = frameRate
  let fileCount
  let mimeType = null
  let maxLength
  let theseTimestamps = []
  if (isVideo) {
    mimeType = 'image/jpeg';
    if (mediaContent === "presentation" && autoSlides) {
        // timestamps = await scanVideoForSlideChanges(singleFile);
        fileCount = timestamps.length;
        if (fileCount > 0) {
            maxLength = Math.ceil(Math.log10(timestamps[timestamps.length - 1] * 1000 + 1)); // Adjust for milliseconds

            timestampStrings = timestamps.map(timestamp => {
                const timestampInMs = (timestamp * 1000).toString();
                return timestampInMs.padStart(maxLength + 3, '0'); // Ensure padding is consistent
            });
        }
    } else {
        const videoDurationInSeconds = await getVideoDuration(singleFile);
        targetFrameRate = frameRate;
        fileCount = Math.ceil(videoDurationInSeconds / targetFrameRate);
        maxLength = Math.ceil(Math.log10(fileCount * targetFrameRate + 1)); // +1 in case fileCount is a perfect power of 10
        console.log(maxLength);
        for (let i = 0; i < fileCount; i++) {
            const timestamp = (i * 1000 * targetFrameRate).toString();
            const paddedTimestamp = timestamp.padStart(maxLength + 3, '0');
            timestampStrings.push(paddedTimestamp);
            theseTimestamps.push(targetFrameRate*i)
        }
      }
      setTimestamps(theseTimestamps)
      
      console.log(timestamps);
      console.log(timestampStrings);
  } else {
      // For photos, extract timestamps using getFileTimestamp
      mimeType = uploadFiles[0].type
      const theseTimestamps = await Promise.all(Array.from(uploadFiles).map(file => getFileTimestamp(file)));
      setTimestamps(theseTimestamps)
      timestampStrings = theseTimestamps
  }
  try {
    console.log("Fetching upload URLs...");
    
    const uploadURLResponse = await apiClient.post('/getUploadURL', {
      isVideo,
      timestamps:timestampStrings,
      sortPreference: isVideo ? null : sortPreference,
      targetFrameRate,
      mimeType,
      uploadName,
      mediaContent,
      originalLanguage,
      targetLanguage
    });

    const { urls, uploadID } = uploadURLResponse.data;
    setUploadID(uploadID)
    console.log({ urls });
    
    if (isVideo) {
      // Handle video by extracting frames and uploading
      await extractAndUploadFrames(singleFile, urls, timestamps);
    } else {
      // Use Promise.all to wait for all file uploads to complete
      const uploadPromises = uploadFiles.map((file, index) => {
        return new Promise(async (resolve) => {
          if (urls[index]) {
            await uploadFile(file, urls[index]);
            resolve(index); // Resolve with the current index
          }
        });
      });

      // Track upload progress
      let completedUploads = 0; // This will keep track of the number of completed uploads

      await Promise.all(uploadPromises.map((promise, index) => {
        return promise.then(() => {
          completedUploads++; // Increment the counter for each resolved promise
          const progress = Math.round((completedUploads / uploadPromises.length) * 100);
          setJobProgress(progress); // Update progress based on the number of completed uploads
          console.log(`Upload progress: ${progress}%`);
        });
      })).then(() => {
        console.log('All files uploaded');
        setJobProgress(100); // Ensure progress is set to 100% once all uploads complete
      }).catch(error => {
        console.error('Error uploading files:', error);
        // Handle any errors here, such as updating UI to indicate an upload failure
      });
    }

    // notify backend of upload completion
    
    const uploadCompleteResponse = await apiClient.post('/uploadComplete', {
      uploadID
    });
    if (uploadCompleteResponse.status === 200) setCurrentStep(2)

    } catch (error) {
    console.error("Error obtaining upload URL: ", error);
  } finally{
    setIsLoading(false)
  }
};

async function scanVideoForSlideChanges(videoFile) {
  setSlideEstimationProgress(0)
  const video = document.createElement('video');
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  video.src = URL.createObjectURL(videoFile);
  await new Promise((resolve) => (video.onloadedmetadata = resolve));

  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  const timestamps = [];
  let currentTime = 0;
  let previousImageData = null;

  while (currentTime <= video.duration) {
    video.currentTime = currentTime;

    // Wait for the video to seek to the currentTime
    await new Promise((resolve) => {
      video.onseeked = async () => {
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const currentImageData = context.getImageData(0, 0, canvas.width, canvas.height);

        if (!previousImageData || await isSignificantChange(previousImageData, currentImageData)) {
          timestamps.push(currentTime);
          console.log("significant change", currentTime)
        } else {
          console.log("not adding to timestamps", currentTime)
        }

        previousImageData = currentImageData;
        resolve();
      };
    });

    currentTime += 2; // Increment currentTime by 1 second for next check
    const progressPercentage = Math.floor((currentTime / video.duration) * 100);
    setSlideEstimationProgress(progressPercentage); // Update progress
  }
  setSlideEstimationProgress(100)
  return timestamps;
}


function handleFrameRateChange(e) {
    const index = Number(e.target.value);
    setFrameRateValue(index);
    setFrameRate(frameRateIncrements[index]);
}

function formatFrameRate(frameRate) {
    if (frameRate < 60) {
        return `${frameRate} seconds per frame`;
    } else {
        const minutes = frameRate / 60;
        return `${minutes} minute${minutes > 1 ? 's' : ''} per frame`;
    }
}

// Utility function to get video duration
function getVideoDuration(file) {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.src = URL.createObjectURL(file);
    video.onloadedmetadata = function() {
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };
  });
}

  const uploadFile = async (file, url) => {
    try {
      setJobStatus("Uploading");
      const headers = { 'Content-Type': file.type };
      await axios.put(url, file, { headers });

      console.log("Upload successful");
      // setJobStatus("Done");
    } catch (error) {
      console.error("Upload error", error.response ? error.response.data : error.message);
      // Handle upload error here
    }
  };

    return (
        <div className="w-3/4 mx-auto p-4 lg:p-8 border rounded-lg shadow-lg">
          {showPricing && (
            <div className="modal modal-open">
              <div className="modal-box flex flex-col items-center gap-2 w-11/12 max-w-5xl">
              <div className="flex flex-col items-center">
                <p className="text-2xl font-bold">Purchase more credits</p>
                <p className="italic text-sm text-center">1 credit = 1 processed video frame or photo.</p>
                <div className="stats shadow">
                  <div className="stat">
                    <div className="stat-title">Credit Balance:</div>
                    <div className="stat-value">{profile?.credits || 0}</div>
                    <div className="stat-desc">credits</div>
                  </div>
                </div>
              </div>
                <PricingPage/>
                <div className="modal-action w-full text-right">
                  <button className="btn" onClick={() => setShowPricing(false)}>Close</button>
                </div>
              </div>
            </div>)}
          {isOpen && <PresentationResultsModal setIsOpen={setIsOpen} downloadURL={downloadURL} setToastData={setToastData}/>}
          <div className="flex justify-center">
            <ul className="steps">
              {steps.map((step, index) => (
                <li
                  key={step}
                  className={`step ${index < currentStep ? 'step-neutral' : ''}`}
                >
                  {step}
                </li>
              ))}
            </ul>
          </div>
          {currentStep === 1 && 
            <div className="flex flex-col items-center gap-2">
            <h2 className="text-2xl font-semibold m-4 text-center">Upload Media</h2>
            <input type="text" placeholder="Name your upload" className="input input-bordered w-full max-w-xs m-4" value={uploadName} onChange={(e) => {setUploadName(e.target.value)}}/>
            {/* {profile?.subscription_tier === "free" && <p className="text-lg text-center">Based on your subscription plan, only the first 25 data points will be evaluated.</p>}
            {profile?.subscription_tier === "basic" && <p className="text-lg text-center">Based on your subscription plan, only the first 50 data points will be evaluated.</p>} */}
            {/* <p className="text-md text-center">Unlock more capability by subscribing to a higher tier.</p>
            <button className="btn btn-success" onClick={()=>navigate("/account")}>See Pricing</button> */}
            <p className="text-md text-center">You can upload a single photo, multiple photos, or a video.</p>
            <p className="text-sm text-center">(.jpg .png .mp4 .mov)</p>
            <div className="mt-4 max-w-full">
              <input
                  type="file"
                  key={inputKey}
                  id="fileInput"
                  onChange={handleFileChange}
                  className="file-input file-input-bordered w-full text-xs sm:text-md"
                  accept="image/*, video/*"
                  multiple
                  />
            </div>
            {estimatedDuration && isVideo &&
            <p className="text-md">Video duration is {formatSeconds(estimatedDuration, "min:sec")} (min:sec).</p>}
             <div className="items-left flex flex-col border rounded-lg p-4 gap-4">
              <p className="text-center">What type of media is this?</p>
              <div className="flex items-center gap-2 tooltip" data-tip="Links text blocks across frames to save as a .csv">
                <input
                  type="radio"
                  id="numericalMediaType"
                  value="exif"
                  checked={mediaContent==="numerical"}
                  onChange={(e)=>setMediaContent("numerical")}
                  className="radio"
                />
                <label htmlFor="numericalMediaType">Numerical Data</label>
              </div>
              <div className="flex items-center gap-2  tooltip" data-tip="Groups each frame's text and exports as single plain text file">
                <input
                  type="radio"
                  id="presentationMediaType"
                  value="lastModified"
                  checked={mediaContent==="presentation"}
                  onChange={(e)=>setMediaContent("presentation")}
                  className="radio"
                />
                <label htmlFor="presentationMediaType">Presentation/Document</label>
              </div>
              <div className="flex items-center gap-2 tooltip" data-tip="Select the caption blocks, save original and translated text as a .csv">
                <input
                  type="radio"
                  id="translateMediaType"
                  value="lastModified"
                  checked={mediaContent==="captions"}
                  onChange={(e)=>setMediaContent("captions")}
                  className="radio"
                />
                <label htmlFor="translateMediaType">Captions</label>
              </div>
            </div>
            {["numerical", "captions", "presentation"].includes(mediaContent) &&
            <div className="flex flex-col items-center gap-2">
              <label htmlFor="original-language-select">Select {mediaContent === "captions" ? "caption" : "character"} Language:</label>
              <select id="original-language-select" className="select select-bordered" value={originalLanguage} onChange={(e)=> setOriginalLanguage(e.target.value)}>
                {languages.filter(({ label }) => label !== "Same (no translation)").map(({ code, label }) => (
                  <option key={code} value={code}>
                    {label}
                  </option>
                ))}
              </select>
            </div>}
            {mediaContent === "captions" &&
            <div className="flex flex-col items-center gap-2">
              <label htmlFor="target-language-select">Select target caption Language:</label>
              <select id="target-language-select" className="select select-bordered" value={targetLanguage} onChange={(e)=> setTargetLanguage(e.target.value)}>
                {languages.filter(({ label }) => label !== "Auto").map(({ code, label }) => (
                  <option key={code} value={code}>
                    {label}
                  </option>
                ))}
              </select>
            </div>}
            <p className="text-md"></p>
            {uploadFiles && uploadFiles.length > 1 && !isVideo && 
            <select id="sortMethod" className="select select-bordered" onChange={handleSortChange}>
              <option defaultValue value="name">Sort by name</option>
              <option value="timestamp">Sort by timestamp</option>
            </select>}
            {
            (!isVideo && sortPreference === "timestamp" && 
            <div className="flex flex-col items-center gap-4">
              <div className="items-center flex flex-col border rounded-lg p-4 gap-4">
                <p>Choose Timestamp Option:</p>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    id="exifTimestamps"
                    value="exif"
                    checked={exifTimestamps}
                    onChange={(e)=>setExifTimestamps(true)}
                    className="radio"
                  />
                  <label htmlFor="exifTimestamps">Use Capture Times</label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    id="lastModifiedTimestamp"
                    value="lastModified"
                    checked={!exifTimestamps}
                    onChange={(e)=>setExifTimestamps(false)}
                    className="radio"
                  />
                  <label htmlFor="lastModifiedTimestamp">Use Last Modified Times</label>
                </div>
              </div>
              <p className="text-md">Estimated duration based on photo timestamps is {formatSeconds(estimatedDuration, "hr:min:sec")} (hr:min:sec).</p>
            </div>)
            }
            {mediaContent === "presentation" && isVideo && 
            <div className="flex items-center gap-2 tooltip" data-tip="Works best with clean screen recordings.">
              <input
                  type="checkbox"
                  id="autoSlides"
                  checked={autoSlides}
                  onChange={(e)=>setAutoSlides(e.target.checked)}
                  className="toggle"
                />
                <label htmlFor="autoSlides">Detect slides automatically</label>
            </div>}
            {isVideo && ["presentation"].includes(mediaContent) && autoSlides && ( timestamps ?
            <p>Detected Slides: {timestamps.length}</p> :
            <div className="flex flex-col items-center">
              <p>Detecting Slide Count</p>
              <progress className="progress w-full" value={slideEstimationProgress} max="100"></progress>
            </div>)
            }
            
            {isVideo && (["numerical", "captions"].includes(mediaContent) || !autoSlides) && 
            <div className="w-full flex flex-col mx-auto items-center">
              <p className="text-center">Frame Rate: {formatFrameRate(frameRate)}</p>
              <input
                  type="range"
                  className="range"
                  min={0}
                  max={frameRateIncrements.length - 1} // Set max as the last index of the array
                  step={1}
                  value={frameRateValue}
                  onChange={handleFrameRateChange}
              />
              {estimatedDuration && estimatedDuration/frameRate < profile?.credits ? 
                <p className={`text-center`}> This will use {Math.max(Math.ceil(estimatedDuration/frameRate),1)} of your {profile?.credits} credits.</p> :
                <div className="flex flex-col items-center justify-center">
                  <p className={`text-center text-error`}>You have {profile?.credits || 0} credits but this operation requires {Math.max(Math.ceil(estimatedDuration/frameRate),1)}.</p>
                  {profile?.credits > 0 && <p className={`text-center text-error`}>Only the first {profile?.credits} frame{profile?.credits > 1 ? 's':''}  will be processed unless you purchase more.</p>}
                  <button className="btn btn-sm mt-2" onClick={()=>setShowPricing(true)}>Purchase Credits</button>  
                </div>
              }
              </div>
            }
            {isVideo && mediaContent === "presentation" && autoSlides && slideEstimationProgress === 100 &&
              <div className="w-full flex flex-col mx-auto items-center">
                {timestamps?.length && timestamps?.length < profile?.credits ? 
                <p className={`text-center`}> This will use {Math.max(Math.ceil(timestamps?.length),1)} of your {profile?.credits} credits.</p> :
                <div className="flex flex-col items-center justify-center">
                  <p className={`text-center text-error`}>You have {profile?.credits || 0} credits but this operation requires {Math.max(Math.ceil(timestamps?.length),1)}.</p>
                  <p className={`text-center text-error`}>Only the first {profile?.credits} frame{profile?.credits > 1 ? 's':''} will be processed unless you purchase more.</p>
                  <button className="btn btn-sm mt-2" onClick={()=>setShowPricing(true)}>Purchase Credits</button>
                </div>
              }
              </div>}
              {uploadFiles && uploadFiles.length > profile.credits && !isVideo && 
              <div className="w-full flex flex-col mx-auto items-center">
                <p className={`text-center text-error`}>You have {profile?.credits || 0} credits but this operation requires {uploadFiles.length}.</p>
                <button className="btn btn-sm mt-2" onClick={()=>setShowPricing(true)}>Purchase Credits</button>  
                </div>
              }
            <button
              className={`btn m-8 btn-success`}
              onClick={handleUpload}
              >
            {isLoading ? <div className="loading loading-spinner loading-sm" /> : 'Upload'}

            </button>
            <br/>
            {/* <div>Status: {jobStatus}</div> */}
            {(jobStatus.startsWith('Uploading')) && (
            <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex flex-col justify-center items-center">
              <div className="bg-neutral p-4 rounded-lg flex flex-col items-center justify-center space-y-2">
                {/* <span className="loading loading-spinner loading-lg text-white"></span> */}
                <div className="radial-progress text-white" style={{"--value":jobProgress}} role="progressbar"></div>
                <p className="text-white text-lg">Uploading</p>
              </div>
            </div>
              )}
              </div>
      }
      {currentStep === 2 && 
        <div className="flex flex-col items-center">
          <h2 className="text-2xl font-semibold m-4 text-center">Processing</h2>
          <p className="text-md text-center">You media is processing.</p>
          <p className="text-md text-center">This page will update when it's complete.</p>
          <p className="text-md text-center">You can also check your uploads page in a few minutes.</p>
          {jobStatus.startsWith('Processing') && (
            <progress className="progress w-1/2 items-center m-8" ></progress>
            )}
            <button className="btn btn-normal mx-auto m-8" onClick={()=>navigate("/uploads")}>Go to Uploads</button>
        </div>}
      {currentStep === 3 && user &&
        <div className="flex flex-col mx-auto justify-center">
          <h2 className="text-2xl font-semibold mx-auto text-center">View Upload</h2>
          <h2 className="text-md font-semibold text-center">Your upload is done processing, view it by clicking below.</h2>
          <button className={`btn btn-success mx-auto m-8`} onClick={handleUploadOpen}>
            {isLoading ? <div className="loading loading-spinner loading-sm" /> : 'View Upload'}
          </button>
        </div>}
      {currentStep !== 1 && 
        <div className="flex flex-col mx-auto justify-center p-4">
          <button className="btn btn-warning mx-auto" onClick={startOver}>Start Over</button>
        </div>}
      </div>
    )
}

export default UploadPage;
