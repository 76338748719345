// StateContext.js
import React, { createContext, useState, useEffect } from 'react';
export const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const storage = window.localStorage; // Assuming this is window.localStorage
  const initialSettings = {theme:"dark", got_started:true}
  const [settings, setSettings] = useState(storage.getItem("settings") ? JSON.parse(storage.getItem("settings")) : initialSettings);
  const [currentUser, setCurrentUser] = useState(null);
  const [uploads, setUploads] = useState(null);
  const [toastData, setToastData] = useState({type:"info", text:"", show:false});
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [fileStatus, setFileStatus] = useState({status:null, progress:null});

  useEffect(() => {
    storage.setItem("settings", JSON.stringify(settings));
  }, [settings]);

  return (
    <StateContext.Provider value={{ initialSettings, settings, setSettings, currentUser, setCurrentUser, uploads, setUploads, toastData, setToastData, fileStatus, setFileStatus }}>
      {children}
    </StateContext.Provider>
  );
};
