import React from 'react';
import { useNavigate } from 'react-router-dom';
import PricingPage from '../components/PricingPage'; // Assuming this contains the pricing info and buy credits options

const OnboardingPage = ({ profile }) => {
    const navigate = useNavigate();

    const handleStartUploading = () => {
        navigate('/upload'); // Navigate to the upload page
    }

    return (
        <div className="w-2/3 p-4 mx-auto flex flex-col items-center justify-center">
            <div className="text-center">
                <h1 className="text-2xl font-bold">Welcome to Textractify!</h1>
                <p className="text-lg mt-2">Start extracting valuable data from your media effortlessly.</p>
            </div>

            <div className="my-6 flex flex-col items-center">
                <h2 className="text-xl font-semibold text-center">How It Works</h2>
                <ol className="list list-decimal pl-5 max-w-3/4">
                    <li>Upload your media: photo, series of photos, or video.</li>
                    <li>Select the type of data to extract: numerical data, presentations, or captions.</li>
                    <li>Option to translate captions into your desired language.</li>
                </ol>
            </div>

            <div className="my-6 flex flex-col items-center">
                <h2 className="text-xl font-semibold text-center">Using Credits</h2>
                <ul className="list list-disc max-w-3/4">
                <li>1 credit is consumed per frame processed.</li>
                <li>New users get 50 credits to test their media.</li>
                <li>To process more, credits can be purchased easily.</li>
                </ul>
            </div>
            <div className="stats shadow">

            <div className="stat">
                <div className="stat-title">Credit Balance:</div>
                <div className="stat-value">{50}</div>
                <div className="stat-desc">credits</div>
              </div>
            </div>
            <div className="">
                <p className="text-2xl font-bold text-center "> Credit Purchase Options</p>
                <PricingPage profile={profile} purchaseButtons={true}/>
            </div>

            <div className="flex flex-col items-center">
                <h2 className="text-xl font-semibold">Ready to Get Started?</h2>
                <button className="btn btn-success mt-4" onClick={handleStartUploading}>
                    Upload Media
                </button>
            </div>
        </div>
    );
};

export default OnboardingPage;
