import React from 'react';
import video_input from '../assets/images/video_input.png'
import {useNavigate } from 'react-router-dom';


function EVChargingPost() {
      const navigate=useNavigate()

  return (
    <div className="container mx-auto p-5">
      <article>
        <header className="mb-8">
          <figure className="mb-4">
            <img src={video_input} alt="charge curve video" className="w-full h-48 object-cover rounded-xl" />
          </figure>
          <h1 className="text-4xl font-bold">Analyzing Electric Vehicle Charge Curves Efficiently with Automated Text Extraction</h1>
          {/* <p className="text-xl text-gray-500">Nature and its beauty</p> */}
          <p className="text-gray-500">Published on April 12, 2024</p>
        </header>
        <section className="space-y-6">
          <p className="text-lg">
            Electric vehicles (EVs) are at the forefront of the automotive industry's shift towards sustainable transportation. As more consumers embrace EV technology, understanding the nuances of EV behavior, such as charge curves, becomes increasingly important. Typically, analyzing a vehicle's charge curve involves recording data like charge state, time, and power draw at regular intervals during a charging session. Traditionally, this data has been manually logged—a time-consuming and error-prone process, especially when dealing with long videos that contain hundreds of data points. This is where automated text extraction tools, like our web app, Textractify, revolutionize the process.
          </p>
          <h2 className='text-xl'>The Traditional Approach: Manual Data Logging</h2>
          {/* <figure className="mb-4">
            <img src="/images/nature1.jpg" alt="Scenic view" className="w-full" />
            <figcaption>Scenic view of the mountains</figcaption>
          </figure> */}
          <p className="text-lg">
            Imagine you're conducting a study on the charging efficiency of different electric vehicles. You set up cameras to record the EV's dashboard throughout a charging session, capturing data displayed on the vehicle's infotainment screen. Traditionally, you would play back the video, pausing frequently to manually enter data points into a spreadsheet. This method not only demands a significant amount of time and focus but also introduces a higher risk of human error, which can skew the results of the study.
          </p>
            <h2 className='text-xl'>The Automated Solution: Textractify</h2>
            <p className="text-lg">Textractify offers a sophisticated yet user-friendly solution that automates the extraction of text from videos. Here’s how it works:</p>
            <ul className="list list-decimal list-inside">
                <li className="list-item"><b>Video Upload:</b> You upload the video of the EV’s charging session directly to Textractify.</li>
                <li className="list-item"><b>Automatic Text Extraction: </b> Using advanced Optical Character Recognition (OCR) technol className="list list-decimal list-inside"ogy powered by Google Vision API, Textractify scans each frame of the video for text. This process automatically detects and records all numerical data displayed on the EV’s dashboard, such as charge percentage and time.</li>
                <li className="list-item"><b>Data Analysis:</b> The extracted data is then available for immediate download or can be viewed directly within the app. This allows for quick comparison and analysis of charge curves across different charging sessions or EV models.</li>
            </ul>

          <h2 className='text-xl'>Benefits of Using Textractify for EV Charge Curve Analysis</h2>
            <ul className="list list-disc list-inside">
                <li className="list-item"><b>Time Efficiency:</b> What would normally take hours of meticulous manual labor can now be completed in a fraction of the time. Textractify processes a full-length charging session video in minutes.</li>
                <li className="list-item"><b>Accuracy: </b> Automated text extraction reduces the likelihood of human error, ensuring data integrity and reliability.</li>
                <li className="list-item"><b>Ease of Use:</b> With a straightforward interface, Textractify is accessible to users with minimal technical expertise. Anyone can analyze charge curves without needing advanced skills.</li>
                <li className="list-item"><b>Scalability:</b> Textractify can handle multiple videos simultaneously, making it easy to scale up studies involving several vehicles or repeated tests.</li>
            </ul>
          <h2 className='text-xl'>Comparing Manual vs. Automated Data Extraction</h2>
          <p className="text-lg">
            In a comparative study where manual data logging was pitted against Textractify's automated process, the differences were stark. Manual logging of a two-hour charging session typically took about three hours, including time for video playback and data entry. In contrast, Textractify extracted the same amount of data in less than 15 minutes.
          </p>
          <p className="text-lg">
            Furthermore, manual logging had an error rate of approximately 5% due to missed data points and misrecorded information. Automated extraction showcased near-perfect accuracy, with an error rate of less than 0.5%.
          </p>
          <h2 className='text-xl'>Conclusion</h2>
          {/* <figure className="mb-4">
            <img src="/images/nature2.jpg" alt="Forest path" className="w-full" />
            <figcaption>Peaceful path through the forest</figcaption>
          </figure> */}
          <p className="text-lg">
            For EV enthusiasts, manufacturers, or researchers, Textractify offers a transformative tool that simplifies the analysis of charge curves. By automating the data extraction process, our tool not only saves valuable time but also enhances the accuracy of the data, empowering users to make informed decisions and insights into EV performance. As we continue to embrace cleaner technologies, tools like Textractify play a crucial role in streamlining research and development processes, ultimately driving innovation in the EV sector.
          </p>
            <p className="text-lg">
                Textractify is more than just a utility—it's an enabler of innovation, making detailed data analysis accessible to everyone in the EV ecosystem. Whether you're performing academic research, working in EV development, or just a tech enthusiast, Textractify is designed to facilitate a deeper understanding of electric vehicles in a way that is both efficient and reliable.
          </p>
            <p className="text-lg">
            Explore how Textractify can enhance your projects and studies by visiting our website and trying out our text extraction capabilities firsthand. The future of EV research is here, and it’s automated.
          </p>
        </section>
      </article>
<div className="flex justify-center">
  <button className="btn btn-sm" onClick={() => navigate('/blog')}>Back to all posts</button>
</div>

    </div>
  );
}

export default EVChargingPost;
