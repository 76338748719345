import React from 'react';
import video_input from '../assets/images/video_input.png'
import {useNavigate } from 'react-router-dom';


function TextractifyOverviewPost() {
  const navigate = useNavigate();
  
  return (
    <div className="container mx-auto p-5">
      <article>
        <header className="mb-8">
          <figure className="mb-4">
            <img src={video_input} alt="video text extraction" className="w-full h-48 object-cover rounded-xl" />
          </figure>
          <h1 className="text-4xl font-bold">Textractify: Revolutionizing Text Extraction from Videos</h1>
          <p className="text-gray-500">Published on April 5, 2024</p>
        </header>
        <section className="space-y-6">
          <p className="text-lg">
            The current landscape of video analysis tools largely revolves around audio transcription, converting spoken words into written text. But what about the text that is visually displayed in the video? That's where Textractify comes in. Unlike traditional services, Textractify extracts text directly from the video, opening up a whole new realm of possibilities for video content analysis.
          </p>

          <h2 className='text-xl'>Beyond Audio Transcription</h2>
          <p className="text-lg">
            Audio transcription services are great for videos where the primary content is spoken. However, they fall short when it comes to videos with on-screen text. This is particularly true for educational videos, tutorials, and presentations where key information is often displayed as on-screen text or captions.
          </p>

          <h2 className='text-xl'>Textractify’s Advanced OCR Technology</h2>
          <p className="text-lg">
            Textractify uses Optical Character Recognition (OCR) technology to analyze each frame of a video, capturing any text that appears on the screen. This text is then exported in usable formats such as .csv and .txt, making it easy to analyze and utilize the extracted data.
          </p>

          <h2 className='text-xl'>Potential Applications</h2>
          <p className="text-lg">
            The potential applications for Textractify's technology are vast. For example, it could be used to extract text from YouTube videos that have on-screen captions but do not have auto-captions enabled. This could dramatically improve accessibility, making it easier for individuals who are deaf or hard of hearing to access the content.
          </p>

          <h2 className='text-xl'>Advantages of Textractify</h2>
          <p className="text-lg">
            Textractify offers several advantages over traditional transcription services. By extracting text directly from the video, it captures information that audio transcription services would miss. This includes on-screen captions, text within graphics, and other visual information. In addition, the exported .csv and .txt files make it easy to analyze and utilize the extracted data.
          </p>

          <h2 className='text-xl'>Conclusion</h2>
          <p className="text-lg">
            Textractify revolutionizes the way we extract and analyze text from videos. By going beyond audio transcription and capturing on-screen text, it provides a more comprehensive analysis of video content. Whether you're looking to improve accessibility, analyze video content, or simply capture all the information in a video, Textractify has you covered.
          </p>
          <p className="text-lg">
            Experience the power of Textractify by visiting our website. Discover how our innovative technology can transform your approach to video analysis. With Textractify, every word counts.
          </p>
        </section>
      </article>
      <div className="flex justify-center">
        <button className="btn btn-sm" onClick={() => navigate('/blog')}>Back to all posts</button>
      </div>
    </div>
  );
}

export default TextractifyOverviewPost;