import React, { useState, useEffect, useContext } from 'react';
import { useUser } from '../utils/UserProvider';  // Import useUser hook from UserProvider
import {Routes, Route, useNavigate } from 'react-router-dom';
import { StateContext } from '../stateContext';
import googleIcon from '../assets/googleIcon.svg';

const SigninPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showSignUp, setShowSignUp] = useState(false)
  const {setToastData} = useContext(StateContext);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
  const [signInLoading, setSignInLoading] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  

  const { profile, user, signOut, signIn, loading, signUp, emailSignIn, handleFirebaseForgotPassword } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && user) {
      navigate("/dashboard")
    }
  }, [loading, user]);

  const handleSignUp = async () => {
        setSignUpLoading(true);
        try {
            await signUp(email, password);
            if (profile?.firstLogin) navigate("/welcome")
            else navigate("/dashboard")
        } catch (error) {
            console.error(error);
            handleAuthError(error);
        } finally {
            setSignUpLoading(false);
        }
    };

    const handleEmailSignIn = async () => {
        setSignInLoading(true);
        try {
            await emailSignIn(email, password);
            if (profile?.firstLogin) navigate("/welcome")
            else navigate("/dashboard")

            // Handle successful sign in
        } catch (error) {
            console.error(error);
            handleAuthError(error);
        } finally {
            setSignInLoading(false);
        }
    };

    const handleForgotPassword = async () => {
      setForgotPasswordLoading(true);
      try {
        await handleFirebaseForgotPassword(email);
        setToastData({ text: 'Password reset link sent to your email.', show: true });
      } catch (error) {
        console.error(error);
        handleAuthError(error);
      
        } finally {
            setForgotPasswordLoading(false);
            setForgotPassword(false)
        }
    };

    const handleAuthError = (error) => {
    let errorMessage = 'An error occurred during authentication.';
    switch (error.code) {
      case 'auth/email-already-in-use':
        errorMessage = 'Email is already in use. Please log in or use a different email.';
        break;
        case 'auth/invalid-email':
          errorMessage = 'Invalid email format.';
          break;
      case 'auth/weak-password':
        errorMessage = 'Password is too weak. Please use a stronger password.';
        break;
      case 'auth/user-disabled':
        errorMessage = 'Your account has been disabled.';
        break;
      case 'auth/missing-email':
        errorMessage = 'Please fill in your email and click "Forgot password" again.';
        break;
      case 'auth/user-not-found':
      case 'auth/invalid-credential':
      case 'auth/wrong-password':
      case 'auth/missing-password':
      case 'auth/too-many-requests':
        errorMessage = 'Invalid email or password.';
        setForgotPassword(true)
        break;
      default:
        errorMessage = 'An error occurred: ' + error.message;
    }
    setToastData({ text: errorMessage, show: true });
  };

  return (
  <div className="flex items-center justify-center h-full w-full pt-8">
        <div className="card w-96 bg-base-100 shadow-xl">
          <div className="card-body">
            <p className="text-xl"> {showSignUp ? "Sign Up" : "Log In"}</p>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Email</span>
              </label>
              <input type="email" placeholder="Email" className="input input-bordered" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            {(!forgotPassword) && <div className="form-control">
              <label className="label">
                <span className="label-text">Password</span>
              </label>
              <input type="password" placeholder="Password" className="input input-bordered" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>}
            {(forgotPassword && !showSignUp) && 
              <div className="form-control mb-8">
                <button className="btn " onClick={handleForgotPassword}>
                  {forgotPasswordLoading ? <div className="loading loading-spinner loading-sm" /> : 'Reset Password'}
                </button>
              </div>}
            {(!showSignUp) && <p className="link text-right" onClick={()=>setForgotPassword(!forgotPassword)}>{(forgotPassword && !showSignUp) ? "I know it now" : "Forgot password?"}</p>}

            {showSignUp && <div className="form-control mt-4">
              <button className="btn btn-primary" onClick={handleSignUp}>
                {signUpLoading ? <div className="loading loading-spinner loading-sm" /> : 'Sign Up'}
              </button>
            </div>}
            {!showSignUp && <div className="form-control mt-4">
              <button className="btn btn-primary" onClick={handleEmailSignIn}>
                {signInLoading ? <div className="loading loading-spinner loading-sm" /> : 'Log In'}
              </button>
            </div>}
            <div className="form-control mt-2">
              <button className="btn btn-normal" onClick={signIn}>
                <img src={googleIcon} alt="Google" style={{width: '36px', height: '36px' }} />
                {showSignUp ? "Sign Up" : "Log In"} with Google
              </button>
              </div>
            <p className="link text-center" onClick={()=>setShowSignUp(!showSignUp)}>{showSignUp ? "Already have an account? Log In" : "New here? Sign Up"}</p>
          </div>
        </div>
      </div>
  );
};

export default SigninPage;
