import React, { useEffect, useState, useContext } from 'react';
import { useUser } from '../utils/UserProvider'; // Adjust the import path as necessary
import { User } from 'react-feather';
import apiClient from "../utils/apiClient";
import PricingPage from '../components/PricingPage';
import { useNavigate } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AccountPage = () => {
    // const { settings, setSettings, setToastData, toastData, selectedFiles, setSelectedFiles, uploads, setUploads, setFileStatus, fileStatus, initialSettings, } = useContext(StateContext);
  const { user, signIn, signOut, profile, fetchUserProfile } = useUser();

  const navigate = useNavigate();

  useEffect(() => {
    fetchUserProfile();
  }, []); // Ensure all dependencies are included


  const handleSignOut = async () => {
    signOut()
    navigate("/")
  }
  return (
      <div className="w-4/5 mx-auto p-4 border rounded-lg shadow-lg">
        <div className="flex flex-col items-center align-middle">
            <p className="text-xl font-bold p-8">My Account</p>
      {user && (
          <div className="flex flex-col gap-4 items-center align-middle">
            {user?.photoURL && <img alt="user" src={user?.photoURL} className="rounded-full"/>}

            <div className="flex flex-col md:flex-row items-center justify-center p-2">
                <p className="p-2">Email:</p>
                <div className="">
                    <input disabled type="text" placeholder="name" id="account_name" value={user.email} className="input input-bordered max-w-xs" />                  
                </div>
            </div>
            {profile?.credits >= 0 && <div className="stats shadow">
              <div className="stat">
                <div className="stat-title">Credit Balance:</div>
                <div className="stat-value">{profile?.credits}</div>
                <div className="stat-desc">credits</div>
              </div>
              
            </div>}
            <div className="flex flex-col items-center">
              <p className="text-2xl font-bold">Purchase more credits</p>
              <p className="italic text-sm text-center">1 credit = 1 processed video frame or photo.</p>
            </div>
            <PricingPage tier={profile?.subscription_tier}/>
            {/* <button className="btn btn-success" onClick={handleSubscribe}>Subscribe</button> */}
            <button className="btn btn-normal" onClick={handleSignOut}>Sign out</button>
            <p className="italic text-center">
              For help or questions reach out to us at <a className="link link-hover" href="mailto:textractifyapp@gmail.com?subject=Help%20Needed&body=Please%20describe%20your%20issue%20or%20question%20here.">TextractifyApp@gmail.com.</a>
            </p>

        </div>
      )}
          </div>
    </div>
  );
};

export default AccountPage;
