import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import video_input from '../assets/images/video_input.png'
import captions from '../assets/images/captions.png'
import spacex_analysis from '../assets/blog/spacex_analysis.png'

// Mock data for blog posts
const posts = [
  {
    id: 'spacex-launch-data-capture',
    title: 'Capturing Spacex Launch Data from Livestream',
    subtitle: 'Extracting text from video frames is the next step past simple audio transcription.',
    date: 'April 24, 2024',
    photo: spacex_analysis,
  },
  {
    id: 'transcription-vs-extraction',
    title: 'Transcription vs Character Extraction',
    subtitle: 'Extracting text from video frames is the next step past simple audio transcription.',
    date: 'April 15, 2024',
    photo: video_input,
  },
  { 
    id: 'ev-charge-curve-analysis',
    title: 'EV Charge Curve Analysis',
    subtitle: 'Analyzing electric vehicle charge curves efficiently with automated text extraction.',
    date: 'April 12, 2024',
    photo: video_input,
  },
  {
    id: 'presentation-text-extraction',
    title: 'Presentation Video Text Extraction',
    subtitle: 'Screen recordings of slideshow presentations are common, but being able to search and copy/paste the contents was difficult, until now.',
    date: 'April 5, 2024',
    photo: video_input,

  },
  {
    id: 'caption-extraction',
    title: 'Video Caption Extraction',
    subtitle: 'Captions are often trapped in video frames, but Textractify is here to save them.',
    date: 'April 1, 2024',
    photo: captions,

  },];

function BlogPage() {
    const navigate = useNavigate()
  return (
    <div className="container mx-auto p-5">
      <h1 className="text-3xl font-bold text-center mb-8">Blog</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {posts.map(post => (
          <div key={post.id} className="card bg-base-100 shadow-xl h-96">
            <figure className="pointer-events-auto hover:cursor-pointer" onClick={() => navigate(`${post.id}`)}><img src={post.photo} alt={post.title} /></figure>
            <div className="card-body">
              <h2 className="card-title">{post.title}</h2>
              <p>{post.subtitle}</p>
              <p className="text-sm text-gray-500">{post.date}</p>
              <div className="card-actions justify-end">
                <Link to={`/blog/${post.id}`} className="btn btn-primary">View Post</Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BlogPage;
