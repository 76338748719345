import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, sendPasswordResetEmail, signOut as firebaseSignOut } from 'firebase/auth';
import apiClient from './apiClient';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);  // Added loading state
  const auth = getAuth();

  const fetchUserProfile = async (firebaseUser) => {
    if (!firebaseUser) firebaseUser = user
    try {
        const token = await firebaseUser.getIdToken(true);
        const response = await apiClient.get(`getUserData`);
        setProfile(response.data);
    } catch (error) {
        console.error("Error fetching user profile: ", error);
    }
  };

    const handleFirebaseForgotPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      // You can handle success message here if needed
    } catch (error) {
      console.error("Error sending password reset email: ", error);
      throw error;  // Throw error to handle it in UI components
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        await fetchUserProfile(firebaseUser);
        setUser(firebaseUser);
        // if (profile?.firstLogin) 
      } else {
        setUser(null);
        setProfile(null);
      }
      setLoading(false);  // Set loading to false after user check
    });

    return () => unsubscribe();
  }, [auth]);

  const signIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Error signing in: ", error);
    }
  };

  const signOut = async () => {
    try {
      await firebaseSignOut(auth);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const signUp = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    setUser(userCredential.user);
    // You can fetch or set user profile here if needed
  } catch (error) {
    console.error("Error signing up: ", error);
    throw error;  // Throw error to handle it in UI components
  }
};

const emailSignIn = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    setUser(userCredential.user);
  } catch (error) {
    console.error("Error signing in with email: ", error);
    throw error;
  }
};

  return (
    <UserContext.Provider value={{ user, profile, signIn, signOut, signUp, emailSignIn, loading, handleFirebaseForgotPassword, setProfile, fetchUserProfile }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
