import axios from 'axios';
import { getAuth } from 'firebase/auth';

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiClient = axios.create({
  baseURL: API_BASE_URL,
});

apiClient.interceptors.request.use(async (config) => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    const token = await user.getIdToken();
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

apiClient.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response && error.response.status === 403) {
    // Handle unauthorized access, e.g., by signing the user out
    const auth = getAuth();
    auth.signOut();
    // Optionally redirect to login page or show a message
  }
  return Promise.reject(error);
});

export default apiClient;
