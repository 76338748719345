import React from 'react';
import spacex_analysis from '../assets/blog/spacex_analysis.png'
import SpeedChart from '../assets/blog/SpeedChart.png';
import LongerLaunch from '../assets/blog/LongerLaunch.gif';
import AltChart from '../assets/blog/AltChart.png';
import BlockCleaning from '../assets/blog/BlockCleaning.png';
import {useNavigate } from 'react-router-dom';


function SpaceXPost() {
    const navigate=useNavigate()

  return (
    <div className="container mx-auto p-5">
      <article>
        <header className="mb-8">
          <figure className="mb-4">
            <img src={spacex_analysis} alt="Textractify screenshot" className="w-full h-96 object-cover rounded-xl" />
          </figure>
          <h1 className="text-4xl font-bold">Extracting Data from SpaceX Launch Livestream Video with Textractify</h1>
          <p className="text-gray-500">Published on April 24, 2024</p>
        </header>
        <section className="space-y-6">
          <p className="text-lg">
            Watching a SpaceX launch livestream, I was intrigued by the speed and altitude data displayed on the screen and wanted to visualize it on a graph. I decided to use Textractify to extract this data automatically rather than manually recording data values while playing back the video. This blog post will walk you through my demonstration of extracting numerical data from a SpaceX launch livestream to get the speed and altitude of the first stage.
          </p>
          <h2 className='text-2xl'>Downloading the Video</h2>
          <p className="text-lg">
            I started by downloading the SpaceX launch livestream from YouTube using an online tool. I saved the video in a format that Textractify supports (e.g., MP4).
          </p>
          <h2 className='text-2xl'>Uploading to Textractify</h2>
          <p className="text-lg">
            After downloading the video, I uploaded it to Textractify.com. Textractify allowed me to select a framerate for the extraction. I chose a 1-second framerate to get data for every second of the launch. Textractify only uploads the frames of interest so it can handle multi-hour videos with ease. It all depends on how many frames your selected framerate results in.
          </p>
          <div className="flex flex-col  sm:flex-row items-center justify-center">
            <figure className="mb-4 mt-4 w-full md:w-1/2">
              <img src={spacex_analysis} alt="Textractify screenshot" className="w-full object-scale-down rounded-xl" />
              <figcaption className="text-center italic text-sm md:text-md pt-2">Textractify interface to select and modify text blocks</figcaption>
            </figure>
            </div>
          <h2 className='text-2xl'>Selecting Blocks of Interest</h2>
          <p className="text-lg">
            Textractify displayed the extracted text in blocks after uploading the video. I selected the blocks that showed the speed and altitude of the first stage. I was able to clean up the selected blocks by inputting characters or words in the block I didn't want and splitting the block on a character if there was additional dynamic data I didn't need.
          </p>
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
            <figure className="mb-4 mt-4 flex-1">
              <img src={LongerLaunch} alt="Textractify screenshot" className="w-full h-full object-cover rounded-xl" />
              <figcaption className="text-center italic text-sm md:text-md pt-2">Textractify blocks are connected across frames using an advanced matching algorithm</figcaption>
            </figure>
            <figure className="mb-4 mt-4 flex-1">
              <img src={BlockCleaning} alt="Textractify screenshot" className="w-full h-full object-cover rounded-xl" />
              <figcaption className="text-center italic text-sm md:text-md pt-2">Block cleaning options to quickly remove unwanted text</figcaption>
            </figure>
          </div>
          <h2 className='text-2xl'>Exporting to Excel</h2>
          <p className="text-lg">
            After selecting the blocks of interest, I exported the data to Excel using Textractify's convenient export function. This allowed me to save the extracted data as a .csv file.
          </p>
          <h2 className='text-2xl'>Creating Plots</h2>
          <p className="text-lg">
            With the data in Excel, I created plots to visualize the speed and altitude of the first stage over time. The resulting plots provided a clear and concise representation of the first stage's performance during the launch.
          </p>
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
            <figure className="mb-4 mt-4">
              <img src={SpeedChart} alt="SpaceX plots" className="w-full object-scale-down rounded-xl" />
            </figure>
            <figure className="mb-4 mt-4">
              <img src={AltChart} alt="SpaceX plots" className="w-full object-scale-down rounded-xl" />
            </figure>
          </div>
          <h2 className='text-2xl'>Conclusion</h2>
          <p className="text-lg">
            This demonstration shows how Textractify can easily extract and analyze on-screen data from livestream videos. Whether you're a space enthusiast, a student, or a professional, Textractify can be a powerful tool for extracting valuable data from videos.
          </p>
        </section>
      </article>
      <div className="flex justify-center">
  <button className="btn btn-sm" onClick={() => navigate('/blog')}>Back to all posts</button>
</div>

    </div>
  );
}

export default SpaceXPost;