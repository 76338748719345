import React, { useState } from 'react';
import apiClient from "../utils/apiClient";
import { useUser } from '../utils/UserProvider'; // Adjust the import path as necessary
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'

function PricingPage({purchaseButtons=true}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCredits, setSelectedCredits] = useState(false);
  const { user, profile,  fetchUserProfile } = useUser();

  const handleSubscribe = async (credits) => {
    try {
        // Check if user is already subscribed, validate email, etc.
        setSelectedCredits(credits)
        setIsLoading(true)
        // Send a request to backend to create a checkout session for the selected tier
        const response = await apiClient.post(`/create-checkout-session`, {
            credits: credits // Send the tier as part of the request
        });

        const session = response.data;

        // Redirect the user to the Stripe Checkout page
        window.open(session.url, '_blank');
        // Periodically fetch user profile every 5 seconds
        const fetchInterval = setInterval(fetchUserProfile, 5000);

        // Clear the interval after 60 seconds (just an example)
        setTimeout(() => {
            clearInterval(fetchInterval);
        }, 120000); // 60 seconds

    } catch (error) {
        console.error('Error subscribing:', error);
        // Optionally set an error state or display a message to the user
    } finally{
        setIsLoading(false)
    }
};

const renderButtonContent = (creditValue) => {
    if (isLoading && selectedCredits === creditValue) {
      return <div className="loading loading-spinner loading-sm" />;
    } else {
      return (
        <div className="flex flex-row items-center">
          Purchase Credits
          <ArrowTopRightOnSquareIcon className="w-4 h-4 inline-block ml-1" />
        </div>
      );
    }
  };

  return (
    <div className={`flex flex-col lg:flex-row justify-center items-start gap-6 my-10`}>
      {/* Free Tier */}
      <div className={`border-4 rounded-lg p-4 max-w-sm w-full`}>
        <h2 className="text-2xl font-bold text-center mb-4">100 Credits</h2>
        <h2 className="text-2xl font-bold text-center mb-4">$1</h2>
        {/* <ul className="list-disc pl-5 mb-4">
          <li>100 timestamps for numerical data</li>
          <li>100 images</li>
          <li>100 slides</li>
        </ul> */}
        {purchaseButtons && <div className="text-center">
            <button className="btn btn-primary" onClick={() => handleSubscribe(100)}>
              {renderButtonContent(100)}
            </button>
        </div>}
        </div>

      {/* Basic Tier */}
      <div className={`border-4 rounded-lg p-4 max-w-sm w-full`}>
        <h2 className="text-2xl font-bold text-center mb-4">500 Credits</h2>
        <h2 className="text-2xl font-bold text-center mb-4">$5</h2>
        {/* <ul className="list-disc pl-5 mb-4">
          <li>3 uploads per day</li>
          <li>50 total data points/images</li>
          <li>3 exported data series</li>
        </ul> */}
        {purchaseButtons && <div className="text-center">
            <button className="btn btn-primary" onClick={() => handleSubscribe(500)}>
              {renderButtonContent(500)}
            </button>
        </div>}
      </div>

      {/* Pro Tier */}
      <div className={`border-4 rounded-lg p-4 max-w-sm w-full`}>
        <h2 className="text-2xl font-bold text-center mb-4">2500 Credits</h2>
        <h2 className="text-2xl font-bold text-center">$20</h2>
        <p className="text-sm text-center mb-4">20% off!</p>
        {/* <ul className="list-disc pl-5 mb-4">
          <li>Unlimited uploads per day</li>
          <li>Unlimited data points/images</li>
          <li>Unlimited exported data series</li>
          <li>Unlimited file count/video duration</li>
        </ul> */}
        {purchaseButtons && <div className="text-center">
            <button className="btn btn-primary" onClick={() => handleSubscribe(2500)}>
              {renderButtonContent(2500)}
            </button>
            </div>}
      </div>


      {/* Enterprise Tier */}
      <div className={`border-4 rounded-lg p-4 max-w-sm w-full`}>
        <h2 className="text-2xl font-bold text-center mb-4">Custom</h2>
        <h2 className="text-lg font-bold text-center">Reach out</h2>
        <h2 className="text-lg font-bold text-center mb-4">to discuss</h2>
        <p className="text-sm text-center mb-4">textractifyapp@gmail.com</p>
        {/* <ul className="list-disc pl-5 mb-4">
          <li>Unlimited uploads per day</li>
          <li>Unlimited data points/images</li>
          <li>Unlimited exported data series</li>
          <li>Unlimited file count/video duration</li>
        </ul> */}
{true && <div className="text-center">
  <a href="mailto:textractifyapp@gmail.com?subject=Enterprise%20Account%20Request" className="btn btn-primary">
    Reach Out
  </a>
</div>}
      </div>
    </div>
  );
}

export default PricingPage;
