// src/firebase-config.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyDENncUIKha5A6LWiPHLfUpPb5vQRTtRn8",
  authDomain: "video-data-extraction.firebaseapp.com",
  databaseURL: "https://video-data-extraction-default-rtdb.firebaseio.com",
  projectId: "video-data-extraction",
  storageBucket: "video-data-extraction.appspot.com",
  messagingSenderId: "61493487010",
  appId: "1:61493487010:web:d67d886e8549572f6ffefb",
  measurementId: "G-FSCC8NG54R"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const analytics = getAnalytics(app);
export { auth, provider, app };
