import './App.css'
import React, {useEffect, useRef, useContext, Suspense, lazy} from 'react';
import {Routes, Route, useNavigate, Navigate, useLocation } from 'react-router-dom';
import AboutPage from './Pages/AboutPage';
import PrivacyPage from './Pages/PrivacyPage';
import HomePage from './Pages/HomePage';
import UploadPage from './Pages/UploadPage';
import UploadsPage from './Pages/UploadsPage';
import AccountPage from './Pages/AccountPage';
import SignInPage from './Pages/SignInPage';
import OnboardingPage from './Pages/OnboardingPage';
import BlogPage from './Pages/BlogPage';
import EVChargingPost from './blogs/EVChargingPost';
import PresentationTextPost from './blogs/PresentationTextPost';
import CaptionsPost from './blogs/CaptionsPost';
import SpaceXPost from './blogs/SpaceXPost';
import { User } from 'react-feather';
import { Helmet } from 'react-helmet';

// import { signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
// import { auth, provider } from './utils/firebase-config';
import TermsPage from './Pages/TermsPage';
// import DashboardPage from './Pages/DashboardPage';
// import ActivityAnalysisPage from './Pages/ActivityAnalysisPage';
import storage from "./utils/local_storage"
import { StateContext } from './stateContext';
import { useUser } from './utils/UserProvider'; // Adjust the import path to your UserProvider file
import CheckoutSuccessfulPage from './Pages/CheckoutSuccessPage';
import CheckoutSuccessPage from './Pages/CheckoutSuccessPage';
import CheckoutCancelPage from './Pages/CheckoutCancelPage';
import TextractifyOverviewPost from './blogs/TextractifyOverviewPost';

const ViewUploadPage = lazy(() => import('./Pages/ViewUploadPage'));

function App() {
  // const canvasRef = useRef(null);
  const {settings, setSettings, setToastData, toastData, initialSettings, currentUser, setCurrentUser} = useContext(StateContext);
  const navigate = useNavigate();
  const { profile, user, signOut, signIn, loading, setProfile } = useUser();
  const location = useLocation();  // Get the current location

  // function handleLogout(){
  //   storage.removeItem("Token")
  //   // navigate('/')
  // }
  // initial load stuff
  useEffect(()  =>  {
    // make sure settings have all of the keys necessary in case I add more
    // console.log(settings)
    const sortedInitialKeys = Object.keys(initialSettings).sort();
    const sortedSettingsKeys = Object.keys(settings).sort();
    // console.log(settings)
    if (JSON.stringify(sortedInitialKeys) !== JSON.stringify(sortedSettingsKeys)) {
      // console.log("settings not equal, adding new keys")
      const updatedSettings = { ...initialSettings, ...settings };
      setSettings(updatedSettings);
    }

    //open getting started modal if first visit
    if (settings && !settings.got_started){
      handleGettingStarted()
    }
    console.log({user, profile})
  }, []);

  const handleGettingStarted = () => {
    document.getElementById('getting_started_modal').showModal();
    setSettings({...settings, got_started:true})
  }

  const handleSignInOut = () => {
    if (user) {
      signOut()
      navigate("/")
    } else signIn()
  }

  useEffect(() => {
    if (!loading && !user && ['/account', '/upload', '/uploads', '/onboarding'].some(path => location.pathname.startsWith(path))) {
      console.log("protected route, heading home")
      navigate("/")
    }
    // if (!loading && user && profile?.firstLogin){
    //   delete profile.firstLogin
    //   setProfile(profile)
    //   navigate("/onboarding")
    // }
  }, [loading, user, location.pathname]);
    
// local storage settings saver
  useEffect(() => {
    storage.setItem("settings", settings);
  }, [settings]);

  // timer to close toast after X seconds
  useEffect(() => {
    let timer;

    if (toastData.show) {
        // Set a timer to automatically close the toast after 30 seconds
        timer = setTimeout(() => {
                  setToastData({...toastData,show: false});
        }, 5000); // 5 seconds
    }

    // Clean-up function to clear the timer
    return () => {
        if (timer) {
            clearTimeout(timer);
        }
    };
  }, [toastData.show]);

  const getTheme = () =>{
    // if (settings.theme === "system") return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    // else 
    return settings.theme
  }

  const setTheme = (e) => {
    // console.log(e.target.checked)
    let these_settings = {...settings}
    if (e.target.checked === true){
      these_settings.theme = "dark"
    } else these_settings.theme = "light"
    setSettings(these_settings)
  }

  return (
  <div data-theme={getTheme()} className="w-full min-h-screen">
    {/* <canvas ref={canvasRef} style={{ display: 'none' }}></canvas> */}
    {toastData.show && 
    <div className="toast toast-center">
      <div className={`alert alert-${toastData.type} flex`}>
        <p className="text-center">{toastData.text}</p>
        {/* <button onClick={() => setToastData({...toastData, show:false})}>
          Close
        </button> */}
      </div>
    </div>}
    <div className="py-4 px-2 sm:px-8 w-full top-0 sticky z-20">
      <div className="navbar rounded-full shadow-lg backdrop-blur-md items-center">
        <div className="navbar-start">
          <div className="dropdown">
            <label tabIndex={0} className="btn btn-ghost btn-circle">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
            </label>
            <ul tabIndex={0} className="menu menu-sm dropdown-content mt-4 z-1 p-2 shadow bg-base-100 rounded-box  w-32">
              <li><p onClick={()=>navigate('/')}>Home</p></li>
              {/* <li><a href="/pricing">Pricing</a></li> */}
              <li><p onClick={()=>navigate('/blog')}>Blog</p></li>
              <li><p onClick={()=>navigate('/about')}>About</p></li>
              <li><p onClick={()=>navigate('/privacy-policy')}>Privacy Policy</p></li>
              <li><p onClick={()=>navigate('/terms')}>Terms of Service</p></li>
            </ul>
          </div>
        </div>
        <div className="navbar-center hover:cursor-pointer" onClick={()=>navigate('/')}>
          <p className="normal-case text-xl font-semibold">Textractify</p>
        </div>
        <div className="navbar-end flex flex-row items-center">
          <div className="hidden sm:block tooltip tooltip-bottom" data-tip="Theme">
            <label className="swap swap-rotate">
              {/* this hidden checkbox controls the state */}
              <input type="checkbox" className="theme-controller" checked={settings.theme !=="light"} onChange={setTheme} />
              <svg className="swap-on fill-current w-7 h-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z"/></svg>
              <svg className="swap-off fill-current w-7 h-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z"/></svg>
            </label>
          </div>
            {/* <p onClick={()=>navigate('/account')}>Credits: {profile?.credits}</p> */}
            <div className="flex-none">
              <ul className="menu menu-horizontal">
                {!user && <li><p onClick={()=>navigate('/login')}>{loading ? <div className="loading loading-spinner loading-sm" /> : "Login"}</p></li>}
                {user && <li>
                  <details>
                    <summary>
                      Menu
                    </summary>
                    <ul className="bg-base-100 shadow-bg-100 mt-4 p-2">
                      <li><p onClick={()=>navigate('/upload')}>New Upload</p></li>
                      <li><p onClick={()=>navigate('/uploads')}>Uploads</p></li>
                      <li><p onClick={()=>navigate('/account')}>Account</p></li>
                      {/* {profile?.credits > -1 && <li><p onClick={()=>navigate('/account')}>Credits: {profile?.credits}</p></li>} */}
                      <li><p onClick={handleSignInOut}>Logout</p></li>
                    </ul>
                  </details>
                </li>}
              </ul>
            </div>
        </div>
      </div>
    </div>
    <div className="flex flex-grow w-full z-10">
      <Suspense fallback={
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex flex-col justify-center items-center">
          <div className="bg-neutral p-4 rounded-lg flex flex-col items-center justify-center space-y-2">
            <span className="loading loading-spinner loading-lg text-white"></span>
            <p className="text-white text-lg">Loading</p>
          </div>
        </div>}>
        <Helmet>
          <link rel="canonical" href={`https://textractify.com${location.pathname}`} />
        </Helmet>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          {/* <Route path="/dashboard" element={<DashboardPage/>} /> */}
          <Route path="/about" element={<AboutPage/>} />
          <Route path="/upload" element={<UploadPage/>} />
          <Route path="/view-upload/:uploadID" element={<ViewUploadPage/>} />
          <Route path="/demo" element={<ViewUploadPage/>} />
          <Route path="/onboarding" element={<OnboardingPage/>} />
          <Route path="/uploads" element={<UploadsPage/>} />
          <Route path="/account" element={<AccountPage/>} />
          <Route path="/login" element={<SignInPage/>}/>
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/ev-charge-curve-analysis" element={<EVChargingPost />} />
          <Route path="/blog/spacex-launch-data-capture" element={<SpaceXPost/>} />
          <Route path="/blog/presentation-text-extraction" element={<PresentationTextPost />} />
          <Route path="/blog/caption-extraction" element={<CaptionsPost />} />
          <Route path="/blog/transcription-vs-extraction" element={<TextractifyOverviewPost />} />
          <Route path="/about" element={<AboutPage/>} />
          <Route path="/terms" element={<TermsPage/>} />
          <Route path="/checkout-success" element={<CheckoutSuccessPage/>} />
          <Route path="/checkout-cancelled" element={<CheckoutCancelPage/>} />
          <Route path="/privacy-policy" element={<PrivacyPage/>} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </div>
    <footer className="footer mt-auto p-4">
      <aside className="flex flex-row w-full items-center justify-between">
        <div className="flex flex-row items-center flex-1">
          {/* <ZestyRidesIcon width="35" height="35" className="text-inherit"/> */}
          <p className="ml-2">Copyright Textractify © 2024 - All right reserved</p>
        </div>
      </aside> 
    </footer>
  </div>
  );
}

export default App;
