export const languages = [
  { label: 'Same (no translation)', code: null },
  { label: 'Auto', code: null },
  { label: 'Afrikaans', code: 'af' },
  { label: 'Arabic', code: 'ar' },
  { label: 'Assamese', code: 'as' },
  { label: 'Azerbaijani', code: 'az' },
  { label: 'Belarusian', code: 'be' },
  { label: 'Bulgarian', code: 'bg' },
  { label: 'Bengali', code: 'bn' },
  { label: 'Tibetan', code: 'bo' },
  { label: 'Breton', code: 'br' },
  { label: 'Bosnian', code: 'bs' },
  { label: 'Catalan', code: 'ca' },
  { label: 'Czech', code: 'cs' },
  { label: 'Welsh', code: 'cy' },
  { label: 'Danish', code: 'da' },
  { label: 'German', code: 'de' },
  { label: 'Greek', code: 'el' },
  { label: 'English', code: 'en' },
  { label: 'Esperanto', code: 'eo' },
  { label: 'Spanish', code: 'es' },
  { label: 'Estonian', code: 'et' },
  { label: 'Basque', code: 'eu' },
  { label: 'Persian', code: 'fa' },
  { label: 'Finnish', code: 'fi' },
  { label: 'Filipino', code: 'fil' },
  { label: 'Fijian', code: 'fj' },
  { label: 'French', code: 'fr' },
  { label: 'Irish', code: 'ga' },
  { label: 'Galician', code: 'gl' },
  { label: 'Gujarati', code: 'gu' },
  { label: 'Hebrew', code: 'he' },
  { label: 'Hindi', code: 'hi' },
  { label: 'Croatian', code: 'hr' },
  { label: 'Haitian', code: 'ht' },
  { label: 'Hungarian', code: 'hu' },
  { label: 'Armenian', code: 'hy' },
  { label: 'Indonesian', code: 'id' },
  { label: 'Icelandic', code: 'is' },
  { label: 'Italian', code: 'it' },
  { label: 'Japanese', code: 'ja' },
  { label: 'Javanese', code: 'jv' },
  { label: 'Georgian', code: 'ka' },
  { label: 'Kazakh', code: 'kk' },
  { label: 'Khmer', code: 'km' },
  { label: 'Kannada', code: 'kn' },
  { label: 'Korean', code: 'ko' },
  { label: 'Kurdish', code: 'ku' },
  { label: 'Kyrgyz', code: 'ky' },
  { label: 'Latin', code: 'la' },
  { label: 'Luxembourgish', code: 'lb' },
  { label: 'Lao', code: 'lo' },
  { label: 'Lithuanian', code: 'lt' },
  { label: 'Latvian', code: 'lv' },
  { label: 'Malagasy', code: 'mg' },
  { label: 'Maori', code: 'mi' },
  { label: 'Macedonian', code: 'mk' },
  { label: 'Malayalam', code: 'ml' },
  { label: 'Mongolian', code: 'mn' },
  { label: 'Marathi', code: 'mr' },
  { label: 'Malay', code: 'ms' },
  { label: 'Maltese', code: 'mt' },
  { label: 'Burmese', code: 'my' },
  { label: 'Nepali', code: 'ne' },
  { label: 'Dutch', code: 'nl' },
  { label: 'Norwegian', code: 'no' },
  { label: 'Nyanja', code: 'ny' },
  { label: 'Odia', code: 'or' },
  { label: 'Punjabi', code: 'pa' },
  { label: 'Polish', code: 'pl' },
  { label: 'Pashto', code: 'ps' },
  { label: 'Portuguese', code: 'pt' },
  { label: 'Romanian', code: 'ro' },
  { label: 'Russian', code: 'ru' },
  { label: 'Sanskrit', code: 'sa' },
  { label: 'Sindhi', code: 'sd' },
  { label: 'Sinhala', code: 'si' },
  { label: 'Slovak', code: 'sk' },
  { label: 'Slovenian', code: 'sl' },
  { label: 'Samoan', code: 'sm' },
  { label: 'Shona', code: 'sn' },
  { label: 'Somali', code: 'so' },
  { label: 'Albanian', code: 'sq' },
  { label: 'Serbian', code: 'sr' },
  { label: 'Sesotho', code: 'st' },
  { label: 'Sundanese', code: 'su' },
  { label: 'Swedish', code: 'sv' },
  { label: 'Swahili', code: 'sw' },
  { label: 'Tamil', code: 'ta' },
  { label: 'Telugu', code: 'te' },
  { label: 'Tajik', code: 'tg' },
  { label: 'Thai', code: 'th' },
  { label: 'Tigrinya', code: 'ti' },
  { label: 'Turkmen', code: 'tk' },
  { label: 'Tagalog', code: 'tl' },
  { label: 'Turkish', code: 'tr' },
  { label: 'Tatar', code: 'tt' },
  { label: 'Uighur', code: 'ug' },
  { label: 'Ukrainian', code: 'uk' },
  { label: 'Urdu', code: 'ur' },
  { label: 'Uzbek', code: 'uz' },
  { label: 'Vietnamese', code: 'vi' },
  { label: 'Xhosa', code: 'xh' },
  { label: 'Yiddish', code: 'yi' },
  { label: 'Yoruba', code: 'yo' },
  { label: 'Chinese (Simplified)', code: 'zh' },
  { label: 'Chinese (Traditional)', code: 'zh-TW' },
  { label: 'Zulu', code: 'zu' },
];
