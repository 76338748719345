import React from 'react';
// import { StateContext } from '../stateContext';


function AboutPage() {
  return (
  <div className="flex flex-col justify-center mx-auto md:w-3/4 lg:w-1/2 space-y-8 p-4">
    <p className="text-xl text-center">About</p>
    <p className="text-left">Textractify is a quick way to extract numerical data from videos or many photos and export it in spreadsheet format.</p>
  </div>
  );
}

export default AboutPage;