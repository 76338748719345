export function formatSeconds(totalSeconds, format="hr:min") {
    if (!totalSeconds) return null
    // console.log(totalSeconds)
    const totalMinutes = Math.floor(totalSeconds / 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const seconds = totalSeconds % 60;

    switch (format) {
        case "hr:min:sec":
            return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        case "hr:min":
            return hours > 0 ? `${hours}:${Math.round(minutes).toString().padStart(2, '0')}` : `${minutes} min`;
        case "min:sec":
            return `${totalMinutes}:${Math.round(seconds).toString().padStart(2, '0')}`;
        default:
            return `${hours} hr ${Math.round(minutes).toString().padStart(2, '0')} min`;
    }
}

