import EXIF from 'exif-js';

function getFileTimestamp(file) {
  return new Promise((resolve, reject) => {
    EXIF.getData(file, function () {
      let timestamp = EXIF.getTag(this, "DateTimeOriginal");

      if (timestamp) {
        // Parse the EXIF timestamp and convert to Unix timestamp
        const [date, time] = timestamp.split(' ');
        const [year, month, day] = date.split(':');
        const [hours, minutes, seconds] = time.split(':');
        const formattedDate = new Date(year, month - 1, day, hours, minutes, seconds);
        resolve(formattedDate.getTime()); // Return Unix timestamp in milliseconds
      } else {
        // Use file's lastModified property if EXIF data is not available
        resolve(file.lastModified);
      }
    });
  });
}

export {getFileTimestamp}